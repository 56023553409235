body {
  background-color: #000;
  font-family: 'Noto Sans', sans-serif; 
}

.carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slide {
  display: none;
}

.active {
  display: block;
}

.carousel img {
  width: 100%;
  height: auto;
}

.category-section {
  margin-top: 20px;
  color: white; 
  padding: 10px 20px;
}

.posters {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 10px;
  align-items: stretch;
}

.posters img {
  width: 200px;
  height: 300px;
}
.poster{
  width: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.poster-img{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

}
.poster-title {
  background: linear-gradient(90deg, #1C1C1E 33.33%, #121212 65.62%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 30px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

}

.carousel-container {
  position: relative;
}

.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #555;
}
